import React from "react";
import { EXPLORER_ADDRESS, TRONSCAN_ADDRESS } from "../../../../config";
import { WalletStatus } from "../../../store/models";
import { ShowIcon } from "../../";
import { formatLocal } from "../../../../utils/number";
import { Menu, Button } from "antd";
import useAuth from '../../../hooks/useAuth'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

import "./AccountMenu.scss";
const { Divider } = Menu;

const AccountMenu = ({ balances, walletStatus, address, toggleGuide }) => {
  const { bnb, cnr, cns } = balances;
  const { logout } = useAuth()
  const { active } = useActiveWeb3React()
  return (
    <Menu theme={"dark"} className="AccountMenu">
      <Menu.Item key="1" disabled className="AccountMenu__balance">
        <ShowIcon icon="tron" /> {formatLocal(bnb.balance, 6, 6)}
      </Menu.Item>
      <Menu.Item key="2" disabled className="AccountMenu__balance">
        <ShowIcon icon="cnr" /> {formatLocal(cnr.balance, 6, 6)}
      </Menu.Item>
      <Menu.Item key="3" disabled className="AccountMenu__balance">
        <ShowIcon icon="cns" /> {formatLocal(cns.balance, 6, 6)}
      </Menu.Item>
      <Divider />
      {walletStatus === WalletStatus.CONNECTED && address && (
        <Menu.Item key="4" className="AccountMenu__item">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${TRONSCAN_ADDRESS}/address/${address}`}
          >
            Transaction History
          </a>
        </Menu.Item>
      )}
      <Menu.Item key="5" className="AccountMenu__item">
        <a target="_blank" rel="noopener noreferrer" href={EXPLORER_ADDRESS}>
          Explorer
        </a>
      </Menu.Item>
      <Menu.Item key="6" className="AccountMenu__item">
        <a target="_blank" rel="noopener noreferrer" href="https://tron.centricswap.com">
          Centric Swap (TRON)
        </a>
      </Menu.Item>
      <Menu.Item key="7" className="AccountMenu__item">
        <a target="_blank" rel="noopener noreferrer" href="https://convert.centric.com">
          Centric Convert (TRON to BSC)
        </a>
      </Menu.Item>
      <Menu.Item key="8" className="AccountMenu__guideTrigger">
        <Button type="link" onClick={() => toggleGuide(true)}>
          About Centric Swap
        </Button>
      </Menu.Item>
      {active && (
        <Menu.Item key="9" className="AccountMenu__logoutItem">
          <Button type="link" onClick={logout}>
            Logout
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default AccountMenu;
