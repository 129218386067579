import React from "react";
import { WalletStatus } from "../../store/models";
import { TruncateAddress } from "../../../utils/text";
import { ShowIcon } from "../";
import AccountMenu from "./AccountMenu/AccountMenu";
import { Dropdown, Button, Badge } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./Header.scss";
import ConnectWalletButton from './ConnectWalletButton';
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

export enum StatusText {
  IDLE = "Not Connected",
  LOADING = "Loading",
  NOTCONNECTED = "Not Connected",
  NOTLOGGEDIN = "Not Logged In",
  CONNECTED = "Connected",
}

const renderStatus = (status) => {
  switch (status) {
    case WalletStatus.IDLE:
    case WalletStatus.LOADING:
      return "processing";
    case WalletStatus.CONNECTED:
      return "success";
    default:
      return "error";
  }
};

const Header = ({ activeWallet, account, toggleGuide, toggleConnect }) => {
  const { walletStatus, address } = activeWallet;
  const { account: web3address, active } = useActiveWeb3React()
  
  return (
    <header className="Header">
      <div>
        <a
          href="https://www.centric.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ShowIcon icon="logo_white" />
        </a>
      </div>
      <div>
        {walletStatus !== WalletStatus.CONNECTED && !active ? (
          <ConnectWalletButton toggleConnect={toggleConnect} />
        ) : (
          <Dropdown
            className="Header__dropdown"
            placement="bottomRight"
            overlay={
              <AccountMenu
                balances={account}
                walletStatus={walletStatus}
                address={web3address}
                toggleGuide={toggleGuide}
              />
            }
          >
            <Button type="link" className="Header__dropdown__button">
              <Badge status={renderStatus(walletStatus)} />
              {walletStatus === WalletStatus.CONNECTED && active && address ? (
                <TruncateAddress children={web3address} address={true} />
              ) : (
                StatusText[walletStatus]
              )}
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
    </header>
  );
};

export default Header;
