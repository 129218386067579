import React from 'react'

const ConnectWalletButton = ({ toggleConnect }) => {
  return (
    <button onClick={() => toggleConnect(true)} className="ConnectWalletButton">
      {'Connect Wallet'}
    </button>
  )
}

export default ConnectWalletButton