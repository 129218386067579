import { useCallback, useState } from "react";
import bscService from "../services/Bsc.service";
import { SwapMessage, SwapStatus } from "../store/models";
import { useWeb3React } from '@web3-react/core'
import { message } from "antd";

const useSwap = () => {
  const [swapStatus, setSwapStatus]: [any, any] = useState(SwapStatus.IDLE);
  const { account, active } = useWeb3React()

  const triggerSwap = useCallback(
    (values, fromCurrency, toCurrency, displayValue) => {
      (async () => {
        try {
          
          if (!account || !active) {
            throw new Error("Wallet not connected");
          }
          const address = account;
          const { amount } = values;
          setSwapStatus(SwapStatus.PROCESSING);
          message.info(
            `Swap started ${amount} ${fromCurrency.toUpperCase()} for ${displayValue} ${toCurrency.toUpperCase()}`,
            0
          );
          const convertAmount = amount.replace(/,/g, "");
          const { hash } = await bscService.doCentricConvert(
            address,
            fromCurrency,
            convertAmount
          );
          message.destroy();
          message.loading(SwapMessage.PROCESSING, 0);
          await bscService.getTransaction(hash);
          setSwapStatus(SwapStatus.SUCCESS);
          message.destroy();
          message.success(SwapMessage.SUCCESS);
          setTimeout(function () {
            setSwapStatus(SwapStatus.IDLE);
          }, 5000);
        } catch (e) {
          message.destroy();
          message.error(e.message);
          setSwapStatus(SwapStatus.FAILED);
          setTimeout(function () {
            setSwapStatus(SwapStatus.IDLE);
          }, 5000);
        }
      })(); 
    }, [account, active] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { swapStatus, triggerSwap };
};

export default useSwap;
