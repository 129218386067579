import React, { Suspense, lazy, useState } from "react";
import { LoadingFrame, Connect } from "../components";
const Home = lazy(() => import("./Home/Home"));

function Routes() {
  const [connectVisible, toggleConnectVisible]: [boolean, any] = useState(false);
  return (
    <Suspense fallback={<LoadingFrame />}>
      <Home toggleConnectVisible={toggleConnectVisible} />
      <Connect isVisible={connectVisible} toggleVisible={toggleConnectVisible} />
    </Suspense>
  );
}

export default Routes;
