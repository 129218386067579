import React from "react";
import { Modal, Divider } from "antd";
import "./Guide.scss";

const Guide = ({ isVisible, toggleVisible }) => {
  return (
    <Modal
      title="Centric Swap guide"
      visible={isVisible}
      footer={false}
      onOk={() => toggleVisible(false)}
      onCancel={() => toggleVisible(false)}
      className="Guide"
      style={{ top: 20 }}
    >
      <h4>How to use Centric Swap</h4>
      <ol className="Guide__steps">
        <li>
          Setup and connect a BSC wallet. For detailed instructions view the <a
            href="https://www.centric.com/swap-connect-guide/"
            target="_blank"
            rel="noopener noreferrer"
          >how to connect your wallet guide</a>.          
        </li>
        <li>
          Send some BNB to your new wallet to pay network transaction fees{" "}
          <strong>
            <i>(we recommend a minimum of 0.0003 BNB per transaction)</i>
          </strong>.
        </li>
        <li>
          Send some <strong>CNR</strong> or <strong>CNS</strong> to your new wallet.
        </li>
        <li>
          Return to <a
            href="https://www.centricswap.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.centricswap.com
          </a>, click <strong>Connect Wallet</strong> and select your installed wallet from the list of options. Then approve the dialog shown by your wallet.
        </li>
        <li>
          Toggle to convert <strong>CNR</strong> to{" "}
          <strong>CNS</strong> or <strong>CNS</strong> to <strong>CNR</strong>.
        </li>
        <li>Enter the amount to convert.</li>
        <li>Click "Swap" to start the conversion.</li>
        <li>
          Click “Approve” in the dialog from your wallet to
          confirm the transaction.
        </li>
        <li>Your conversion will now take place.</li>
      </ol>
      <Divider />
      <h4>About Centric Swap</h4>
      <p>
        Use Centric Swap to convert between Centric Rise{" "}
        <strong>(CNR)</strong> and Centric Swap <strong>(CNS)</strong>. It
        offers a decentralized alternative to the{" "}
        <a
          href="https://wallet.centric.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Official Centric Wallet
        </a>
        .
      </p>
      <p>
        Before using Centric Swap to convert your Centric tokens please review
        the questions below; Is Centric Swap Right For Me?
      </p>
      <Divider />
      <h4>Is Centric Swap Right For Me?</h4>
      <ul className="Guide__checklist">
        <li>
          I hold Centric Rise <strong>(CNR)</strong> or Centric Swap{" "}
          <strong>(CNS)</strong> and want to convert between them?
        </li>
        <li>
          I prefer not to store my <strong>(CNR)</strong> or{" "}
          <strong>(CNS)</strong> on the{" "}
          <a
            href="https://wallet.centric.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Official Centric Wallet
          </a>
          ?
        </li>
        <li>
          I want to pay a small transaction fee, approx $0.2 - $0.5, in BNB to convert my tokens?
        </li>
      </ul>
      <p>
        If you answered no to any of these questions we recommend you use the{" "}
        <a
          href="https://wallet.centric.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Official Centric Wallet
        </a>
        .
      </p>
      <Divider />
      <h4>Additional Resources</h4>
      <ul className="Guide__steps">
        <li>
          Swap TRON-CNR and TRON-CNS using{" "}
          <a
            href="https://tron.centricswap.com"
            target="_blank"
            rel="noopener noreferrer"
          >Centric Swap (TRON)</a>.          
        </li>
        <li>
        Convert TRON-CNR to BSC-CNR{" "}
          <a
            href="https://convert.centric.com"
            target="_blank"
            rel="noopener noreferrer"
          >Centric Convert</a>.          
        </li>
      </ul>
    </Modal>
  );
};

export default Guide;
