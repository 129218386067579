import { Currency, ValidationMessage } from "../store/models";
import { RISE_ADDRESS, SWAP_ADDRESS, RISE_ABI, SWAP_ABI } from "../../config";
import { formatDecimal, formatBnbDecimal } from "../../utils/number";
import { ethers } from "ethers";
import { simpleRpcProvider } from '../../utils/providers';

class BscService {
  public web3: any;
  public riseContract: any;
  public swapContract: any;

  private getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    const signerOrProvider = signer ?? simpleRpcProvider
    return new ethers.Contract(address, abi, signerOrProvider)
  }
  
  private getBep20Contract = (address: string, abi: any, signer?: ethers.Signer | ethers.providers.Provider) => {
    return this.getContract(abi, address, signer)
  }

  public getRiseContract = async () => {
    return this.getBep20Contract(RISE_ADDRESS, RISE_ABI, this.web3);
  };

  public getSwapContract = async () => {
    return this.getBep20Contract(SWAP_ADDRESS, SWAP_ABI, this.web3);
  };

  public async getAccount(address: string) {
    try {
      if (
        !this.web3 ||
        !this.riseContract ||
        !this.swapContract
      ) {
        throw new Error(ValidationMessage.PRICE);
      }

      const bnbBalancePromise = simpleRpcProvider.getBalance(address);
      const riseBalancePromise = this.riseContract.balanceOf(address);
      const swapBalancePromise = this.swapContract.balanceOf(address);

      const [bnbBalance, riseBalance, swapBalance] = await Promise.all([
        bnbBalancePromise,
        riseBalancePromise,
        swapBalancePromise,
      ]);

      return {
        bnbBalance: formatBnbDecimal(Number(bnbBalance)),
        riseBalance: formatDecimal(riseBalance),
        swapBalance: formatDecimal(swapBalance),
      };
    } catch (e) {
      throw e;
    }
  };

  public getTransaction = (hash, numOfRetries: number = 50) => {
    console.info("Waiting for transaction result...");
    return new Promise((resolve, reject) => {
      const checkTransaction = async () => {
        try {
          if (numOfRetries > 0) {
            if (!this.web3) {
              reject(ValidationMessage.WALLETCONNECT);
            }
            const transaction = await simpleRpcProvider.getTransaction(
              hash
            );
            if (
              Object.prototype.hasOwnProperty.call(transaction, "confirmations") &&
              transaction.confirmations > 0
            ) {
              resolve(hash);
            } else {
              numOfRetries--;
              setTimeout(function () {
                checkTransaction();
              }, 1000);
            }
          } else {
            if (!this.web3) {
              reject(ValidationMessage.WALLETCONNECT);
            }
            reject(new Error(`Transaction error: ${hash} not found`));
          }
        } catch (e) {
          numOfRetries--;
          setTimeout(function () {
            checkTransaction();
          }, 1000);
        }
      };
      checkTransaction();
    });
  };

  public doCentricConvert = async (address, currency, convertAmount) => {
    try {
      if (
        !this.web3 ||
        !this.riseContract ||
        !this.swapContract
      )
        throw new Error(ValidationMessage.PRICE);

      const amount = ethers.utils.parseEther(convertAmount).div('10000000000');
      
      const tokenBalance =
        currency === Currency.CNR
          ? await this.riseContract.balanceOf(address)
          : await this.swapContract.balanceOf(address);

      // ensure sufficient token balance
      if (ethers.BigNumber.from(tokenBalance).lt(amount))
        throw new Error(
          currency === Currency.CNR
            ? ValidationMessage.cnr
            : ValidationMessage.cns
        );

      // ensure sufficient bnb balance
      const bnbBalance = await simpleRpcProvider.getBalance(address);;
      if (formatBnbDecimal(Number(bnbBalance)) < 0.001)
        throw new Error(ValidationMessage.bnb);

      // TODO: check CNR price hasnt changed
      let transaction;
      if (currency === Currency.CNR) {
        transaction = await this.riseContract.convertToSwap(amount.toHexString());
      } else if (currency === Currency.CNS) {
        transaction = await this.riseContract.convertToRise(amount.toHexString());
      } else {
        throw new Error("Invalid token");
      }
      return transaction;
    } catch (e) {
      console.error(e)
      if (Object.prototype.hasOwnProperty.call(e, "message")) {
        throw e;
      }
      throw new Error(e);
    }
  };
}

const bscService = new BscService();

export default bscService;
