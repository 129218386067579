import sample from 'lodash/sample'
import { NODE_1, NODE_2, NODE_3 } from '../config';

// Array of available nodes to connect to
export const nodes = [NODE_1, NODE_2, NODE_3]

const getNodeUrl = () => {
  return sample(nodes)
}

export default getNodeUrl