import React from "react";
import { Modal } from "antd";
import useAuth from '../../../hooks/useAuth'
import config from "./config";
import WalletCard from './components/WalletCard'

import "./Connect.scss";

const Connect = ({ isVisible, toggleVisible }) => {
  const { login } = useAuth()
  return (
    <Modal
      title="Connect a wallet"
      visible={isVisible}
      footer={false}
      onOk={() => toggleVisible(false)}
      onCancel={() => toggleVisible(false)}
      className="Connect"
      style={{ top: 20 }}
      width={400}
    >
      {config.map((entry, index) => (
        <WalletCard
          key={entry.title}
          login={login}
          walletConfig={entry}
          onDismiss={() => toggleVisible(false)}
          mb={index < config.length - 1 ? "8px" : "0"}
        />
      ))}
      <a
        href="https://www.centric.com/swap-connect-guide/"
        target="_blank" rel="noopener noreferrer"
        className="Connect__link"
      >
        Learn how to connect
      </a>
    </Modal>
  );
};

export default Connect;
